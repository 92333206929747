import * as Yup from 'yup'
import {
  format as fnsFormat,
  differenceInYears,
  isFuture,
  addDays,
  isPast,
} from 'date-fns'

Yup.addMethod(Yup.string, 'formatOfDate', function formatOfDate(
  formats,
  message
) {
  // need to be string for custom error message
  return this.test('testFormat', message, function testFormat(value) {
    if (value) {
      return value === fnsFormat(new Date(value), formats)
    }
    return true
  })
})
Yup.addMethod(Yup.string, 'fullAge', function fullAge(years, message) {
  // need to be string for custom error message
  return this.test('testAge', message, function testAge(value) {
    if (value) {
      return differenceInYears(new Date(), new Date(value)) >= years
    }
    return true
  })
})
Yup.addMethod(Yup.string, 'notInFuture', function notInFuture(message) {
  // need to be string for custom error message
  return this.test('testAge', message, function testAge(value) {
    if (value) {
      return !isFuture(new Date(value))
    }
    return true
  })
})
Yup.addMethod(Yup.string, 'notInPast', function notInPast(message) {
  // need to be string for custom error message
  return this.test('testDateNotInPast', message, function testDateNotInPast(
    value
  ) {
    if (value) {
      const date = addDays(new Date(value), 1)
      return !isPast(date)
    }
    return true
  })
})

Yup.addMethod(Yup.string, 'notInNearFuture', function notInNearFuture(
  addedDays,
  message
) {
  // need to be string for custom error message
  return this.test(
    'testDateNotInNearFuture',
    message,
    function testDateNotInNearFuture(value) {
      if (value) {
        const dateform = new Date(value)
        const minDate = addDays(new Date(), addedDays)
        return dateform > minDate
      }
      return true
    }
  )
})
Yup.addMethod(Yup.string, 'notInFarFuture', function notInFarFuture(
  addedDays,
  message
) {
  // need to be string for custom error message
  return this.test(
    'testDateNotInFarFuture',
    message,
    function testDateNotInFarFuture(value) {
      if (value) {
        const dateform = new Date(value)
        const maxDate = addDays(new Date(), addedDays)
        return dateform < maxDate
      }
      return true
    }
  )
})
Yup.addMethod(Yup.string, 'minValue', function minValue(
  message,
  minValueParam
) {
  return this.test('testMin', message, function testMin(value) {
    if (!value) {
      return true
    }
    return parseFloat(value.replace(/,/g, '.')) >= minValueParam
  })
})
Yup.addMethod(Yup.string, 'maxValue', function maxValue(
  message,
  maxValueParam
) {
  return this.test('testMax', message, function testMax(value) {
    if (!value) {
      return true
    }
    return parseFloat(value.replace(/,/g, '.')) <= maxValueParam
  })
})
Yup.addMethod(Yup.string, 'atLeastOneOf', function atLeastOneOf(list, message) {
  return this.test('testAtLeastOneOf', message, function testAtLeastOneOf(
    value
  ) {
    if (value === undefined) {
      return list.some(item => this.parent[item] !== undefined)
    }
    return true
  })
})
Yup.addMethod(Yup.string, 'validateNif', function validateNif(message) {
  return this.test('testNif', message, function testNif(nif) {
    if (!nif) return true
    let sum = 0
    let control = 0
    if (nif.length === 9) {
      sum =
        nif[7] * 2 +
        nif[6] * 3 +
        nif[5] * 4 +
        nif[4] * 5 +
        nif[3] * 6 +
        nif[2] * 7 +
        nif[1] * 8 +
        nif[0] * 9

      const mod = sum % 11
      if (mod !== 0 && mod !== 1) {
        control = 11 - mod
      }
      if (parseInt(nif[8], 10) === control) {
        return true
      }
    }
    return false
  })
})

export default Yup
