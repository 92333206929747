import { DataModelState } from '../models/DataModelState'
import {
  DataModelsActions,
  SET_DATA_MODELS,
} from './actions/data-models.actions'

export function setDataModels(data): DataModelsActions {
  return {
    type: SET_DATA_MODELS,
    payload: data,
  }
}

export function getDataModels(store) {
  return store.dataModel.data
}

const initialState: DataModelState = {
  data: { data: {} },
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_MODELS:
      return {
        ...state,
        data: action.payload,
      }

    default:
      return state
  }
}
