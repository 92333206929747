export const FETCH_TRANSACTION_PENDING =
  'transaction//FETCH_TRANSACTION_PENDING'
export const FETCH_TRANSACTION_DONE = 'transaction//FETCH_TRANSACTION_DONE'
export const FETCH_TRANSACTION_ERROR = 'transaction//FETCH_TRANSACTION_ERROR'
export const SET_ACTUAL_PAGE = 'transaction//SET_ACTUAL_PAGE'
export const SET_SESSION_EXPIRED = 'transaction//SET_SESSION_EXPIRED'
export const SET_PAGE_VISITED = 'transaction//SET_PAGE_VISITED'

interface PendingAction {
  type: typeof FETCH_TRANSACTION_PENDING
  payload: {}
}

interface DoneAction {
  type: typeof FETCH_TRANSACTION_PENDING
  payload: any
}

interface ErrorAction {
  type: typeof FETCH_TRANSACTION_ERROR
  payload: any
}

interface SetActualPageAction {
  type: typeof SET_ACTUAL_PAGE
  payload: any
}
interface SetSessionExpired {
  type: typeof SET_SESSION_EXPIRED
  payload: any
}
interface SetLastPageVisited {
  type: typeof SET_PAGE_VISITED
  payload: any
}
export type TransactionActions = PendingAction | DoneAction | ErrorAction
