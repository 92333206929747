export const FETCH_STOREFRONT_PENDING = 'storefront//FETCH_STOREFRONT_PENDING'
export const FETCH_STOREFRONT_DONE = 'storefront//FETCH_STOREFRONT_DONE'
export const FETCH_STOREFRONT_ERROR = 'storefront//FETCH_STOREFRONT_ERROR'
export const FETCH_STOREFRONT_STORE_ID = 'storefront//FETCH_STOREFRONT_STORE_ID'
export const FETCH_STOREFRONT_FILES = 'storefront//FETCH_STOREFRONT_FILES'
export const FETCH_STOREFRONT_TOKEN = 'storefront//FETCH_STOREFRONT_TOKEN'
export const FETCH_STOREFRONT_GDPR = 'storefront//FETCH_STOREFRONT_GDPR'

interface PendingAction {
  type: typeof FETCH_STOREFRONT_PENDING
  payload: {}
}

interface DoneAction {
  type: typeof FETCH_STOREFRONT_DONE
  payload: any // TODO: change when we now the data type here
}

interface ErrorAction {
  type: typeof FETCH_STOREFRONT_ERROR
  payload: any // TODO: change when we now the error type here
}

interface StoreIdAction {
  type: typeof FETCH_STOREFRONT_STORE_ID
  payload: any // TODO: change when we now the error type here
}

interface FilesAction {
  type: typeof FETCH_STOREFRONT_FILES
  payload: any
}

interface TokenAction {
  type: typeof FETCH_STOREFRONT_TOKEN
  payload: any
}

interface GdprAction {
  type: typeof FETCH_STOREFRONT_GDPR
  payload: boolean
}

export type StoreFrontActionTypes =
  | PendingAction
  | DoneAction
  | ErrorAction
  | StoreIdAction
  | FilesAction
  | TokenAction
  | GdprAction
