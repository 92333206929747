import { createBrowserHistory } from 'history'
import { parse } from 'qs'

const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASE_PATH,
})

const getQsParam = (queryString, parameter) => {
  // OPS-3257 - remove the trailing '%20 added by Outlook'
  const correctQueryString = queryString.endsWith('%20')
    ? queryString.substring(0, queryString.length - 3)
    : queryString

  const str = parse(correctQueryString, { ignoreQueryPrefix: true })[parameter]
  return str || ''
}

history.pushPreservePath = newPath => {
  // window.du_ErrorCausingUrl = window.location.href
  history.push(newPath)
}

export { history, getQsParam }
