export const RESET_FORM_DATA = 'form//RESET_FORM_DATA'
export const SAVE_FORM_DATA = 'form//SAVE_FORM_DATA'
export const SET_VALID_SECTIONS = 'form//SET_VALID_SECTIONS'

interface SaveFormDataAction {
  type: typeof SAVE_FORM_DATA
  payload: any
}

interface ResetFormDataAction {
  type: typeof RESET_FORM_DATA
}

interface SetValidSections {
  type: typeof SET_VALID_SECTIONS
  payload: any
}

export type FormActions =
  | SaveFormDataAction
  | ResetFormDataAction
  | SetValidSections
