import { SessionState } from '../models/SessionState'
import { SessionActions, SET } from './actions/session.actions'

export function setSession(data): SessionActions {
  return {
    type: SET,
    payload: data,
  }
}

export function getSession(store) {
  return store.session.data
}

const initialState: SessionState = {
  data: { pin: '' },
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET:
      return {
        ...state,
        data: action.payload,
      }

    default:
      return state
  }
}
