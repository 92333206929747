/* eslint-disable  */
import {get} from 'lodash'
import { getBrandName } from '../../config'
import { Analytics } from './index'

export function pushStorefrontDataToDatalayer(storefrontData){
  const numberOfOrderedCars = get(storefrontData, 'financialProduct.numberOfOrderedCars')
  Analytics.addToDatalayer({
    'product[0].attributes.recurringPayment': numberOfOrderedCars ?
      get(storefrontData, 'financialProduct.calculation.subsequentInstallmentTotal') * numberOfOrderedCars
      : get(storefrontData, 'financialProduct.calculation.subsequentInstallmentTotal'),
    'product[0].attributes.duration': get(storefrontData, 'financialProduct.calculation.duration'),
    'product[0].attributes.yearlyMileage': get(storefrontData, 'financialProduct.calculation.yearlyMileage'),
    'product[0].vehicleModel[0].manufacturer': get(storefrontData, 'vehicleData.model.manufacturer'),
    'product[0].vehicleModel[0].name': get(storefrontData, 'vehicleData.model.name'),
    'product[0].vehicleModel[0].descriptionLong': get(storefrontData, 'vehicleData.model.descriptionLong'),
    'product[0].vehicleModel[0].colorExterior': get(storefrontData, 'vehicleData.model.colorExterior'),
    'customerData.loginStatus': true,
    'core.attributes.brand': getBrandName().toUpperCase()
  })
}
